import { createAxiosByinterceptors } from "@/uits/request";
import i18n from '../lang'

const request = createAxiosByinterceptors({
    baseURL: 'https://api.ksaasapi.com/api',
    timeout: 10000,
});
const request2 = createAxiosByinterceptors({
    baseURL: 'https://www.let-talks.cc/api',
    timeout: 10000,
});
// 轮询消息
export function getNoRead(data) {
    return request2({
        url: "/mobile/getNoRead",
        method: "get",
        loading: false,
        params: data,
    });
}
// 注册
export function Sign(data) {
    return request({
        url: "/register",
        method: "post",
        loading: false,
        data,
    });
}

// 登录
export function Login(data) {
    return request({
        url: "/login",
        method: "post",
        loading: false,
        data,
    });
}
// 获取用户信息
export function getInfo(data,loading) {
    return request({
        url: "/getInfo",
        method: "post",
        loading: loading||false,
        data,
    });
}
// 获取轮播图及合作企业及滚动轮播
export function getBanner(data) {
    return request({
        url: "/getBanner",
        method: "get",
        loading: true,
        data,
    });
}
// 获取联系客服
export function getService(data) {
    return request({
        url: "/getService",
        method: "post",
        loading: true,
        data,
    });
}
// 订单列表
export function orderList(data,loading) {
    return request({
        url: "/orderList",
        method: "post",
        loading: loading,
        data,
    });
}
// 匹配订单
export function matchOrder(data) {
    return request({
        url: "/matchOrder",
        method: "post",
        loading: false,
        data,
    });
}
// 确认订单
// export function confirmOrder(data) {
//     return request({
//         url: "/confirmOrder",
//         method: "post",
//         loading: true,
//         loadingTxt:i18n.tc("request.loadingText"),
//         data,
//     });
// }
export function confirmOrder(data) {
    return request({
        url: "/confirmOrder",
        method: "post",
        loading: true,
        loadingTxt:i18n.tc("request.loadingText"),
        data,
    });
}
// 获取团队
export function getTeam() {
    return request({
        url: "/getTeam",
        method: "post",
        loading: true,
        // data,
    });
}
// 提现
export function withdraw(data) {
    return request({
        url: "/withdraw",
        method: "post",
        loading: true,
        data,
    });
}
// 充值
export function recharge() {
    return request({
        url: "/recharge",
        method: "post",
        loading: true,
        // data,
    });
}
// 更新用户信息
export function updateUserInfo(data) {
    return request({
        url: "/updateUserInfo",
        method: "post",
        loading: true,
        data,
    });
}
// 资金明细
export function getBalances(data) {
    return request({
        url: "/getBalances",
        method: "post",
        loading: true,
        data,
    });
}
// 充值记录
export function getBalances2(data) {
    return request({
        url: "/getRecharge",
        method: "post",
        loading: true,
        data,
    });
}
// 提现记录
export function getWithdraw(data) {
    return request({
        url: "/getWithdraw",
        method: "post",
        loading: true,
        data,
    });
}
// 领取
export function receiveBonus(data) {
    return request({
        url: "/receiveBonus",
        method: "post",
        loading: true,
        data,
    });
}