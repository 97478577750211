export default {
  "logo": {
    "name": "TIK tak",
    "msg": "TIKTOK Objednávacie centrum",
    "wz": "URL:"
  },
  "head": { "Language": "slovenský" },
  "menu": {
    "home": "predná strana",
    "order": "objednať",
    "trading": "obchod",
    "earnings": "tím",
    "mine": "užívateľ"
  },
  "back": {
    "appDownload": "Sťahovanie aplikácií",
    "help": "Pomoc",
    "order": "objednať",
    "trading": "obchod",
    "income": "tím",
    "revenueDetails": "Podrobnosť",
    "Withdrawal": "vyberať",
    "Recharge": "doplniť",
    "Invitefriends": "pozvať priateľov",
    "personalinformation": "osobné informácie",
    "gywm": "o nás",
    "kf": "zákaznícky servis"
  },
  "loading": {
    "loadingText": "načítava...",
    "finishedText": "Nikdy viac",
    "loadingText2": "Zodpovedajúci ...",
    "loadingLogin": "prihlasovanie...",
    "loadingSign": "Registrovať ...",
    "confirmOrder": "V transakciách ...",
    "ldg1": "Pri odchode ..."
  },
  "Toast": { "copy": "Replikácia", "ts": "Vyberte jazyk" },
  "login": {
    "title": "Ahoj!",
    "msg": "Prihláste sa do svojho účtu",
    "phone": "účtovať",
    "phoneCode": "Región",
    "password": "heslo",
    "remember": "Pamätáš si ma",
    "forget": "Zabudnite na heslo?",
    "loginBtn": "Prihlásiť sa",
    "noAccount": "Žiadny účet?",
    "goRegister": "Registrovať",
    "lg1": "Ďalšie prihlásenie do účtu"
  },
  "sign": {
    "phone": "Telefónne číslo",
    "phoneCode": "Región",
    "Nickname": "Názov prezývky",
    "code": "Overovací kód",
    "sendCode": "Pošlite overovací kód",
    "password": "heslo",
    "confirmPassword": "Potvrďte heslo",
    "recommendationCode": "Odporúčanie",
    "protocol": "Register znamená, že súhlasíte s našimi zmluvnými podmienkami",
    "registerBtn": "Registrovať",
    "Existing": "Existujúci účet?",
    "goLogin": "Prihlásiť sa",
    "rule": {
      "phone": "Účet nemôže byť prázdny, formát účtu je nesprávny",
      "nickname": "Prezývka by mala byť vyplnená",
      "password": "Heslo nemôže byť prázdne, heslo je minimálne 6 číslic a heslo je nekonzistentné.",
      "code": "musí byť vyplnený overovací kód",
      "isAgree": "Najskôr skontrolujte súhlas"
    }
  },
  "home": {
    "cumulativeIncome": "Kumulatívny príjem",
    "notification": "upozorniť",
    "moduleTitle1": "Moja služba",
    "moduleTitle2": "Partnera",
    "title": "upozorniť"
  },
  "download": {
    "TIKTOKSHOP": "Obchod Tiktok",
    "AndroidDownload": "Stiahnutie systému Android",
    "iOSDownload": "Stiahnutie Apple"
  },
  "help": {
    "bz2": "spoločný problém",
    "bz3": "Obchodné pravidlá",
    "bz4": "Ako vybrať peniaze",
    "bz5": "Ako nabíjať",
    "bz6": "1. Čo Istiktokshop?",
    "bz7": "Tiktokshop je spoločnosť na propagáciu marketingu, ktorá pomáha obchodníkom v internetovom obchode Tiktokshop z celého sveta, aby získala viac predaja objednávok a zvýšila údaje o sledovaní z internetových obchodov Tiktokshop. Zaviazali sme sa, že medzi Tiktokshop a obchodníkmi a spotrebiteľmi vytvoríme model marketingu s tromi stratami. Kombinácia najnovšej technológie blockchain P2P rýchlo pripojte spotrebiteľov a obchodníkov prostredníctvom USDT (TRC20, ERC20). Registrovaní používatelia môžu získať províziu objednávok, zatiaľ čo obchodníci môžu zvýšiť údaje o predaji v obchode. Je to najnovší model zisku v modeli internetu blockchain!",
    "bz8": "2. Ako pracovať Ontiktokshop?",
    "bz9": "Prostredníctvom dennej spätnej väzby spoločnosti Tiktokshop je potrebné vylepšiť výrobky, ktoré je potrebné vylepšiť pri predaji Tiktokshop. Používatelia platia sumu objednávky prostredníctvom blockchain USDT a získajú denné provízie.",
    "bz10": "3. Prečo je v týchto dvoch transakciách rozdiel v cene rovnakej meny?",
    "bz11": "Cenové rozdiely sú spôsobené všetkými faktormi, ktoré nevedú k slobode meny, vrátane rýchlosti prenosu meny, podmienok siete, obmedzení prístupu k menu, ľudí v rôznych regiónoch a dokonca aj typov obchodovania a transakcií poskytovaných výmenou. Preto rovnaká mena môže spôsobiť cenové rozdiely v rôznych transakciách.",
    "bz12": "4. Zisk investícií?",
    "bz13": "Čím vyššia je cena, tým vyšší je zisk. Tiktokshop zároveň náhodne prideľuje veľké príkazy na províziu.",
    "bz14": "Systém každý deň automaticky generuje a distribuuje 60 komoditných objednávok používateľom. Objednávky veľkých provízií sú náhodne distribuované.",
    "bz15": "USDT je ​​možné stiahnuť po 60 objednávkach. (Ak nie je dokončené 60 objednávok, systém sa automaticky zastaví až do výšky prebytku objednávky dňa)",
    "bz16": "Každý účet môže byť viazaný iba na účet USDT.",
    "bz17": "Po dokončení denného dodania objednávky môžete normálne stiahnuť.",
    "bz18": "Pred dobíjaním adresu USDT, ktorú môžete kliknúť na aplikáciu alebo webovú stránku, môžete kliknúť na nabíjanie alebo na webovú stránku a vyberte blockchain (TRC-20).",
    'bz19': "Je zakázané vykonávať vzájomné dobíjanie, bankové prevody, výmenné prevody a pod. medzi súvisiacimi účtami. Toto správanie je podozrivé zo zlomyseľného získavania objednávok.",
  },
  "order": {
    "incomplete": "zbavený",
    "completed": "dokončený",
    "orderNumber": "číslo objednávky",
    "transactionTime": "hodina transakcie",
    "price": "Výška",
    "expectedReturns": "Očakávaný návrat",
    "submit": "Predložiť objednávku"
  },
  "trading": {
    "accountBalance": "Zostatok na účte",
    "introduce": "Úvod transakcie",
    "freeze": "Dokončené číslo",
    "walletBalance": "Celková úloha",
    "startMatching": "Začnite porovnávať",
    "yield": "Dnešné výhody",
    "orderNumber": "číslo objednávky",
    "transactionTime": "hodina transakcie",
    "price": "Výška",
    "expectedReturns": "Očakávaný návrat",
    "startTrading": "Začať obchodovať",
    "cancel": "Zrušiť",
    "ts": "Nedostatočná rovnováha, stále chudobná",
    "msg": "Tiktokshop používa spätnú väzbu od spoločnosti Tiktokshop každý deň na zobrazenie produktov, ktoré je potrebné vylepšiť pre predaj Tiktokshop. Ak používateľ potrebuje kliknúť iba na objednávku, systém automaticky vygeneruje podpis objednávky. Užívateľ platí sumu objednávky prostredníctvom blockchainu USDT a zakaždým môže získať viac ako 0,6%provízie a systém náhodne distribuuje veľké príkazy na odmenu.",
    "ts2": "náznak",
    "ts3": "Nedostatočná rovnováha",
    "ts4": "Choďte na dobitie",
    "ts5": "Zrušiť"
  },
  "income": {
    "totalRevenue": "Vyvážiť",
    "residualIncome": "provízia",
    "claim": "prijímať",
    "moduleTitle": "môj tím",
    "headcount": "Celkovo",
    "totalRecharge": "Celkový nabíjanie",
    "grade": "užívateľ",
    "numberOfPeople": "Príspevok (množstvo)",
    "income": "štát",
    "viewDetails": "Zobraziť podrobnosti",
    "task_num": "zbavený",
    "task_num2": "dokončený"
  },
  "revenueDetails": {
    "totalRevenue": "Celkové príjmy",
    "headcount": "Celkovo",
    "totalRecharge": "Celkový nabíjanie",
    "userLevel1": "Úroveň používateľa 1",
    "userLevel2": "Úroveň používateľa 2",
    "userLevel3": "Úroveň používateľa 3",
    "Nickname": "Názov prezývky",
    "phone": "Telefónne číslo",
    "Recharge": "doplniť",
    "income": "príjmy",
    "date": "Registračný čas"
  },
  "user": {
    "Myaccount": "môj účet",
    "Withdrawal": "vyberať",
    "Recharge": "doplniť",
    "InviteFriend": "Pozvi svojich priateľov",
    "InviteFriendTxt": "Za každý zisk získaný priateľmi získate zodpovedajúcu províziu",
    "InviteFriendBtn": "Pozvať teraz",
    "MyInformation": "osobné informácie",
    "FundsDetails": "Kapitálové podrobnosti",
    "RechargeRecord": "Nabíjanie",
    "WithdrawalRecord": "Záznamy o výbere",
    "Language": "Jazyk",
    "Logout": "Odhlásiť Sa"
  },
  "Withdrawal": {
    "address": "Odberateľská adresa",
    "placeholder1": "Odberateľská adresa",
    "availableBalance": "Disponibilný zostatok",
    "placeholder2": "Sumy",
    "actualBalance": "Vlastne",
    "placeholder3": "Vlastne",
    "password": "heslo",
    "placeholder4": "Zadajte 6-16-bitové heslo",
    "submit": "Predložiť",
    "amount": "Nie väčšie ako dostupné sumy",
    "w1": "Manipulačný poplatok",
    "ts1": "Získajte adresu výberu",
    "ts2": "Zadajte sumu výberu"
  },
  "Recharge": {
    "qrcode": "Skenovať nabíjanie kódu",
    "copy": "Kliknutím na skopírovanie",
    "cotakecarepy": "Poznámka, súvisiacim úvahou je použitie vašej obrazovky alebo nehnuteľností na stránku",
    "ts1": "Nabíjanie",
    "ts2": "Ďalšie množstvo",
    "ts3": "Samozrejme",
    "ts4": "Najskôr pripojte peňaženku",
    "ts5": "Vyberte výšku dobíjania",
    "ts6": "Nabíjanie ..."
  },
  "Invitefriends": {
    "inviteCode": "Pozvánka",
    "inviteTxt": "Pozvite priateľov, užite si veľké množstvo príjmu, pozvite prosím",
    "copy": "Kliknutím na skopírovanie",
    "Rebaterules": "Zľava",
    "RebaterulesMsg": "Musia byť splnené nasledujúce podmienky",
    "scale": "Pravidlá Komisie sú nasledujúce",
    "Userlevel": "úroveň používateľa",
    "Rules": "vláda",
    "Commissionratio": "Provízia",
    "ps": "Komisia je urovnaná s USDT, ktorý je možné kedykoľvek stiahnuť do vašej peňaženky"
  },
  "personalinformation": {
    "Nickname": "Názov prezývky",
    "phone": "účtovať",
    "Loginpassword": "prihlasovacie heslo",
    "address": "Odberateľská adresa",
    "modify": "Revidovať",
    "Confirm": "Samozrejme"
  },
  "fundDetails": {
    "amount": "Výška",
    "revenue": "príjmy",
    "time": "čas",
    "withdrawal": "vyberať",
    "recharge": "doplniť",
    "type": {
    "1": "doplniť",
    "2": "vyberať",
    "3": "Zber komisie",
    "4": "príjmy",
    "5": "Vyberať",
    "6": "Prebiehajúci",
    "7": "dokončený",
    "8": "zamietnutý"
  }
  },
  "language": {
    "placeholder": "Zadajte kód Medzinárodnej oblasti",
    "Confirm": "Samozrejme",
    "cancel": "Zrušiť"
  },
  "request": {
    "loadingText": "načítava...",
    "Serverexception": "Abnormality servera"
  },
  "newLang": { "a1": "Rovnováha je zlá", "a2": "Aktuálne číslo úlohy","a3": "Najprv prejdite na adresu záväznej odberu","a4": "Nedostatočný zostatok", "a5": "Či sa nabíjať" },
  "newLang2": {
    "a1": "Ak chcete získať záväznú adresu, kontaktujte zákaznícky servis a upravte sa a upravte sa",
    "a2": "Treba nabíjať",
    "a3": "Ak chcete upraviť heslo, kontaktujte zákaznícky servis"
  },
  "selectPay": { "title": "Platiť", "a1": "Predložiť" },
  "newWithdrawal": { "a1": "spôsob platby" }
}
