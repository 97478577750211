export default {
    "logo": { "name": "Tiktok", "msg": "Tiktok Order Center", "wz": "URL:" },
    "head": { "Language": "chinês" },
    "menu": {
        "home": "primeira página",
        "order": "Ordem",
        "trading": "troca",
        "earnings": "equipe",
        "mine": "do utilizador"
    },
    "back": {
        "appDownload": "Download do aplicativo",
        "help": "ajuda",
        "order": "Ordem",
        "trading": "troca",
        "income": "equipe",
        "revenueDetails": "Detalhe",
        "Withdrawal": "retirar",
        "Recharge": "completar",
        "Invitefriends": "convide amigos",
        "personalinformation": "informações pessoais",
        "gywm": "sobre nós",
        "kf": "atendimento ao Cliente"
    },
    "loading": {
        "loadingText": "carregando...",
        "finishedText": "Não mais",
        "loadingText2": "Coincidindo ...",
        "loadingLogin": "login ...",
        "loadingSign": "Registro ...",
        "confirmOrder": "na transação ..."
    },
    "Toast": { "copy": "Replicação", "ts": "Selecione o idioma" },
    "login": {
        "title": "Olá!",
        "msg": "Faça login na sua conta",
        "phone": "Telefone",
        "phoneCode": "Código Regional",
        "password": "senha",
        "remember": "lembre de mim",
        "forget": "Esqueça a senha?",
        "loginBtn": "Conecte-se",
        "noAccount": "Sem conta?",
        "goRegister": "Registro"
    },
    "sign": {
        "phone": "Número de telefone",
        "phoneCode": "Código Regional",
        "Nickname": "Apelido",
        "code": "Código de verificação",
        "sendCode": "Envie o código de verificação",
        "password": "senha",
        "confirmPassword": "Confirme sua senha",
        "recommendationCode": "Código de recomendação",
        "protocol": "Registro significa que você concorda com nossos termos e condições",
        "registerBtn": "registro",
        "Existing": "Conta existente?",
        "goLogin": "Conecte-se",
        "rule": {
            "phone": "O número do telefone celular não pode estar vazio, o formato do número de telefone celular está incorreto",
            "nickname": "O apelido deve ser preenchido",
            "password": "A senha não pode estar vazia, a senha é mínima de 6 dígitos e a senha é inconsistente.",
            "code": "O código de verificação deve ser preenchido",
            "isAgree": "Por favor, verifique o consentimento primeiro"
        }
    },
    "home": {
        "cumulativeIncome": "Renda cumulativa",
        "notification": "notificar",
        "moduleTitle1": "Meu serviço",
        "moduleTitle2": "Parceiro"
    },
    "download": {
        "TIKTOKSHOP": "Tiktok Store",
        "AndroidDownload": "Download do Android",
        "iOSDownload": "Download da Apple"
    },
    "help": {
        "bz2": "problema comum",
        "bz3": "Regras de negociação",
        "bz4": "Como retirar dinheiro",
        "bz5": "Como recarregar",
        "bz6": "1. O que o ISTIKTOKSHOP?",
        "bz7": "A TikTokshop é uma empresa de promoção de marketing que ajuda os comerciantes da TikTokshop online Store de todo o mundo a obter mais vendas de pedidos e aumentar os dados de visualização das lojas on -line da Tiktokshop. Estamos comprometidos em estabelecer um modelo de marketing de lucro de três partidos entre Tiktokshop e comerciantes e consumidores. Combinando a mais recente tecnologia Blockchain P2P, conecta rapidamente consumidores e comerciantes através do USDT (TRC20, ERC20). Os usuários registrados podem obter a Comissão de Pedidos, enquanto os comerciantes podem aumentar os dados de vendas da loja. É o modelo de lucro mais recente no modelo Blockchain da Internet!",
        "bz8": "2. Como trabalhar ontiktokshop?",
        "bz9": "Através do feedback diário do TikTokshop, os produtos que precisam ser aprimorados nas vendas do TikTokshop precisam ser aprimorados. Os usuários pagam o valor do pedido através do USDT da blockchain e recebem comissões diárias.",
        "bz10": "3. Por que há uma diferença no preço da mesma moeda nas duas transações?",
        "bz11": "As diferenças de preços são causadas por todos os fatores que não são propícios à liberdade de moeda, incluindo a velocidade de transferência de moeda, condições de rede, restrições de acesso à moeda, pessoas em diferentes regiões e até mesmo os tipos de negociação e transações fornecidas pela troca. Portanto, a mesma moeda pode causar diferenças de preços em diferentes transações.",
        "bz12": "4. Lucro do investimento?",
        "bz13": "Quanto maior o preço que você obtém, maior o lucro obtido. Ao mesmo tempo, o Tiktokshop aloca aleatoriamente grandes ordens de comissão.",
        "bz14": "Todos os dias, o sistema gera e distribui automaticamente 60 pedidos de commodities ao usuário. Grandes ordens de comissão são distribuídas aleatoriamente.",
        "bz15": "O USDT pode ser retirado após 60 ordens. (Se os 60 pedidos não forem concluídos, o sistema parará automaticamente até o valor do excesso de pedido do dia)",
        "bz16": "Cada conta só pode estar vinculada a uma conta USDT.",
        "bz17": "Depois de concluir a entrega diária de pedidos, você pode se retirar normalmente.",
        "bz18": "Antes de recarregar, vincule seu endereço de retirada (suporte TRC-20) USDT.",
    'bz19': "É proibido realizar recargas mútuas, transferências bancárias, transferências de câmbio, etc. entre contas relacionadas. Este comportamento é suspeito de captura de ordem maliciosa. Se tal comportamento for detectado, a conta será banida.",

    },
    "order": {
        "incomplete": "desfeito",
        "completed": "concluído",
        "orderNumber": "número do pedido",
        "transactionTime": "Hora da transação",
        "price": "Quantia",
        "expectedReturns": "Retorno esperado",
        "submit": "Enviar pedido"
    },
    "trading": {
        "accountBalance": "Saldo da conta",
        "introduce": "Introdução à transação",
        "freeze": "Número concluído",
        "walletBalance": "Tarefa total",
        "startMatching": "Comece a combinar",
        "yield": "Benefícios de hoje",
        "orderNumber": "número do pedido",
        "transactionTime": "Hora da transação",
        "price": "Quantia",
        "expectedReturns": "Retorno esperado",
        "startTrading": "Começar a negociar",
        "cancel": "Cancelar",
        "ts": "Equilíbrio insuficiente, ainda pobre",
        "msg": "A TikTokshop usa o feedback do TikTokshop todos os dias para exibir produtos que precisam ser aprimorados para as vendas do TikTokshop. Se o usuário precisar clicar apenas no pedido, o sistema gerará automaticamente uma assinatura de pedido. O usuário paga o valor do pedido através do Blockchain do USDT e pode obter mais de 0,6%da comissão de cada vez, e o sistema distribui aleatoriamente grandes ordens de recompensa."
    },
    "income": {
        "totalRevenue": "Equilíbrio",
        "residualIncome": "comissão",
        "claim": "receber",
        "moduleTitle": "meu time",
        "headcount": "Pessoas totais",
        "totalRecharge": "Recarga total",
        "grade": "do utilizador",
        "numberOfPeople": "Contribuição (quantidade)",
        "income": "estado",
        "viewDetails": "Ver detalhes",
        "task_num": "desfeito",
        "task_num2": "concluído"
    },
    "revenueDetails": {
        "totalRevenue": "Rendimento total",
        "headcount": "Pessoas totais",
        "totalRecharge": "Recarga total",
        "userLevel1": "Nível 1 do usuário 1",
        "userLevel2": "Nível 2 do usuário",
        "userLevel3": "Nível 3 do usuário 3",
        "Nickname": "Apelido",
        "phone": "Número de telefone",
        "Recharge": "completar",
        "income": "renda",
        "date": "Tempo de inscrição"
    },
    "user": {
        "Myaccount": "minha conta",
        "Withdrawal": "retirar",
        "Recharge": "completar",
        "InviteFriend": "Convide seus amigos",
        "InviteFriendTxt": "Para cada lucro obtido pelos amigos, você receberá a Comissão de Proporção Correspondente",
        "InviteFriendBtn": "Convite agora",
        "MyInformation": "informações pessoais",
        "FundsDetails": "Detalhes de capital",
        "RechargeRecord": "Recarregar o registro",
        "WithdrawalRecord": "Recorde de retiradas",
        "Language": "linguagem",
        "Logout": "sair"
    },
    "Withdrawal": {
        "address": "Endereço de retirada",
        "placeholder1": "Endereço de retirada",
        "availableBalance": "Saldo disponível",
        "placeholder2": "Quantidade retirada",
        "actualBalance": "Na verdade",
        "placeholder3": "Na verdade",
        "password": "senha",
        "placeholder4": "Por favor, digite a senha de 6 a 16 bits",
        "submit": "enviar",
        "amount": "Não é maior que as quantidades disponíveis"
    },
    "Recharge": {
        "qrcode": "Recarga de código de varredura",
        "copy": "Clique para copiar",
        "cotakecarepy": "Observe que uma consideração relacionada é usar sua tela ou página imobiliária"
    },
    "Invitefriends": {
        "inviteCode": "Código de Convite",
        "inviteTxt": "Convide amigos, desfrute de uma grande quantidade de renda, por favor, convide",
        "copy": "Clique para copiar",
        "Rebaterules": "Desconto",
        "RebaterulesMsg": "As seguintes condições devem ser atendidas",
        "scale": "Regras de taxa de comissão são as seguintes",
        "Userlevel": "nível de usuário",
        "Rules": "regra",
        "Commissionratio": "taxa de comissão",
        "ps": "A Comissão está liquidada com USDT, que pode ser retirada para sua carteira a qualquer momento"
    },
    "personalinformation": {
        "Nickname": "Apelido",
        "phone": "Número de telefone",
        "Loginpassword": "senha de login",
        "address": "Endereço de retirada",
        "modify": "Rever",
        "Confirm": "Claro"
    },
    "fundDetails": {
        "amount": "Quantia",
        "revenue": "renda",
        "time": "tempo",
        "withdrawal": "retirar",
        "recharge": "completar",
        "type": {
            "1": "completar",
            "2": "retirar",
            "3": "Coleta de comissão",
            "4": "renda",
            "5": "Retirar",
            "6": "Pendente",
            "7": "concluído",
            "8": "rejeitado"
        }
    },
    "language": {
        "placeholder": "Por favor, insira o código da área internacional",
        "Confirm": "Claro",
        "cancel": "Cancelar"
    },
    "request": {
        "loadingText": "carregando...",
        "Serverexception": "Anormalidades do servidor"
    },
    "newLang": {
        "a1": "O equilíbrio é ruim",
        "a2": "Número da tarefa atual",
        "a3": "Por favor, vá para o endereço de retirada vinculativo primeiro",
        "a4": "Saldo insuficiente",
        "a5": "Se deve recarregar"
    },
    "newLang2": {
        "a1": "Para o endereço de ligação, entre em contato com o atendimento ao cliente para modificar",
        "a2": "Precisa recarregar",
        "a3": "Entre em contato com o atendimento ao cliente para modificar a senha"
    },
    "selectPay": { "title": "Pagar", "a1": "enviar" },
    "newWithdrawal": { "a1": "Forma de pagamento" }
}


